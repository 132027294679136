import { Observable } from 'rxjs';
import {
  UserCreateDto,
  UserDto,
  UserEditDto,
  UserEditStateDto,
  UserPageQueryParamsDto,
  UserPageDto,
  UserShowDto,
  UserGetAllDto,
} from '../dtos/user.dto';

export abstract class UserRepository {
  abstract getAll(params?: any): Observable<UserGetAllDto>;
  abstract getPage(params: UserPageQueryParamsDto): Observable<UserPageDto>;
  abstract create(user: UserCreateDto): Observable<UserDto>;
  abstract update(userId: UserEditDto): Observable<UserDto>;
  abstract updateState(user: UserEditStateDto): Observable<UserDto>;
  abstract show(userId: number): Observable<UserShowDto>;
}
